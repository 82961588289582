import axios from 'axios';
import queryString from 'query-string';
import { Nodeurl, pythonUrl } from '../../../Data/Variables';

export function renameNestedKeySelective(obj, oldKey, newKey, targetPath) {
  const isPathEqual = (path1, path2) => {
      // Check if both paths are equal
      if (path1.length !== path2.length) return false;
      for (let i = 0; i < path1.length; i++) {
          if (path1[i] !== path2[i]) return false;
      }
      return true;
  };

  const traverseAndRename = (current, currentPath = []) => {
      const keys = Object.keys(current);
      const newObj = {};

      keys.forEach((key) => {
          const value = current[key];
          const newPath = currentPath.concat(key);

          if (key === oldKey && isPathEqual(newPath.slice(0,-1), targetPath)) {
              newObj[newKey] = typeof value === 'object' && value !== null ? traverseAndRename(value, newPath) : value;
          } else {
              newObj[key] = typeof value === 'object' && value !== null ? traverseAndRename(value, newPath) : value;
          }
      });

      return newObj;
  };

  // Start the traversal with the original object
  return traverseAndRename(obj);
}


function numberWithCommas(x) {
    return x ? x.toFixed(2).toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ",") : undefined;
}
function numberWithOutCommas(x) {
  return x.toString().replaceAll(",","");
}
const mongoNodeApiCall = (rubric, call, input,errorHandle) => 
{
    const requestData = {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      url: "https://api.bademeister-jan.pro/" + rubric + "/" + call,
      data: queryString.stringify(input),
    };
    const request = axios(requestData);
    request.catch((err) => {console.log(err.response.data.message);errorHandle();});
    return request;
};
const PythonApiCall = async (call, input,errorHandle) => 
{
    const requestData = {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      url: pythonUrl + "/" + call,
      data: queryString.stringify(input),
    };
    const request = await axios(requestData);
    request.catch((err) => {console.log(err.response.data.message);alert("Data Error. Please contact us at data@bitsonic.ai");errorHandle();});
    return request;
};
const NodeApiCall = (call, input,errorHandle) => 
{
    const requestData = {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      url: Nodeurl + "/" + call,
      data: queryString.stringify(input),
    };
    const request = axios(requestData);
    request.catch((err) => {console.log(err.response.data.message);errorHandle();});
    return request;
};
const apFly = (call, input) => {
  const requestData = {
    method: "post",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    url: "https://api.bademeister-jan.pro:337/" + call,
    data: queryString.stringify(input),
  };
  const request = axios(requestData);
  request.catch((err) => {alert("Currency Error, Please contact us");})
  return request;
};

export {apFly,NodeApiCall,PythonApiCall,mongoNodeApiCall,numberWithCommas,numberWithOutCommas};