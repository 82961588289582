/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect,useState } from 'react';
import Select from 'react-select'
import {useDispatch,useSelector} from "react-redux";
import { useCookies } from 'react-cookie';
import makeAnimated from 'react-select/animated';

import {setActionToDo,setCatalogResFilt} from '../../../redux/portal';
import { ApiCall, getValue, pythonUrl } from '../../../Data/Variables';


const SelectValues = (props) =>
{
    const dispatch = useDispatch();
    const { catalogResFilt } = useSelector((state) => state.portal);
    const { theCatalog } = useSelector((state) => state.portal);
    const animatedComponents = makeAnimated();
    const [filterTitle,setFilterTitle] = useState("");
    const [selectDicti,setSelectDicti] = useState([]);
    const [isOnceDownload,setIsOnceDownload] = useState(true);
    const [classInputFiles,setClassInputFiles] = useState(["button is-small is-light","button is-small is-light"])
    const [, setCookies, ] = useCookies();

    useEffect(()=> {changedTitle({currentTarget : {value : ""}});},[]);
    const changedTitle = (e) =>
    {
        let dicti = [];
        if(e.currentTarget.value in props.filterValues)
        {
            props.filterValues[e.currentTarget.value].forEach((item,index) =>
            {
                dicti[index] = {value : item, label: item};
            })
        }
        setSelectDicti(dicti);

        if(e.currentTarget.value !== filterTitle)
        {
            let ary = [...catalogResFilt[theCatalog]];
            let firstAry = {...ary[props.indexFather]};      
            delete firstAry[filterTitle];
            ary[props.indexFather] = firstAry;
            dispatch(setCatalogResFilt({...catalogResFilt,[theCatalog] : ary}));
            setFilterTitle(e.currentTarget.value);
        }
    }
    const changedSelect = (e) =>
    {
        let ary = [...catalogResFilt[theCatalog]];
        let firstAry = {...ary[props.indexFather]};      
        firstAry[filterTitle] = e.map((e) => e.value);
        if(firstAry[filterTitle].length === 0)
            delete firstAry[filterTitle];
        ary[props.indexFather] = firstAry;
        dispatch(setCatalogResFilt({...catalogResFilt,[theCatalog] : ary}));
    }
    const uploadExcel = async (e) =>
    {
        let input = document.createElement('input');
        input.style.visibility = 'hidden';
        input.type = 'file';
        input.onchange = e => 
        { 
            let file = e.target.files[0]; 
            setClassInputFiles([classInputFiles[0],"button is-small is-light is-loading"]);
            let req = new XMLHttpRequest();
            req.open("POST", pythonUrl+"/Excel2List", true);
            req.responseType = 'json';
            let formData = new FormData();
            formData.append('file', file)
            formData.append('listtype', 'filter')
            req.withCredentials = false;        
            req.send(formData);

            req.onreadystatechange = () =>
            {
                console.log(req.response);
                if (req.readyState === XMLHttpRequest.DONE && req.response !== "NONE")
                {
                    const jsonstring = req.response;
                    let ary = [...catalogResFilt[theCatalog]]
                    ary[props.indexFather] = {...catalogResFilt[theCatalog][props.indexFather],[filterTitle] : jsonstring}
                    dispatch(setCatalogResFilt({...catalogResFilt,[theCatalog] : ary}));
                    setClassInputFiles([classInputFiles[0],"button is-small is-light"]);
                    req.abort();
                }
            }
            req.onerror = () =>
            {
                alert("Data Error. Please contact us at data@bitsonic.ai");
                setClassInputFiles([classInputFiles[0],"button is-small is-light"]);
                req.abort();
            }
        }
        input.click();
    
    }
    const downloadExcel = async (e,jwt) =>
    {
        setClassInputFiles(["button is-small is-light is-loading",classInputFiles[1]]);
     
        let params = 
        {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded',"Authorization" : jwt},
            body: new URLSearchParams(
            {
                "data" : JSON.stringify(props.filterValues[filterTitle]),
                "column_name" : filterTitle,
                "listtype" : "filter"
            })
        }
        let data = await ApiCall(pythonUrl+"/List2Excel",params,setCookies,(err) => 
        {
            alert("Data Error. Please contact us at data@bitsonic.ai");
            setClassInputFiles(["button is-small is-light",classInputFiles[1]]);
        });
        
        const blob = await data.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", "file.xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
        setClassInputFiles(["button is-small is-light",classInputFiles[1]]);
    }
    return (<div className='column'>
                <div className='columns is-vcentered is-centered m-0'>
                    <div className='column is-narrow' style={{"paddingRight" : "0"}}>
                        <div className="select" >
                            <select onChange={(e) => changedTitle(e)} value={filterTitle}>
                                <option value={""}>Select Title...</option> 
                                <option value={getValue(props.readToshort,"Song Name"           ,"Song Name"           )}>Song Name</option> 
                                <option value={getValue(props.readToshort,"Income Type"         ,"Income Type"         )}>Income Type</option>
                                <option value={getValue(props.readToshort,"Payor"               ,"Payor"               )}>Payor</option>
                                <option value={getValue(props.readToshort,"Source"              ,"Source"              )}>Source</option>
                                <option value={getValue(props.readToshort,"Territory"           ,"Territory"           )}>Territory</option>
                                <option value={getValue(props.readToshort,"Release Date"        ,"Release Date"        )}>Release Date</option>
                                <option value={getValue(props.readToshort,"Release Year"        ,"Release Year"        )}>Release Year</option>
                                <option value={getValue(props.readToshort,"Song Share %"        ,"Song Share %"        )}>Song Share %</option>
                                <option value={getValue(props.readToshort,"Artist"              ,"Artist"              )}>Artist</option>
                                <option value={getValue(props.readToshort,"Product"             ,"Product"             )}>Product</option>
                                <option value={getValue(props.readToshort,"Income Type II"      ,"Income Type II"      )}>Income Type II</option>
                                <option value={getValue(props.readToshort,"Production Title"    ,"Production Title"    )}>Production Title</option>
                                <option value={getValue(props.readToshort,"Episode Title"       ,"Episode Title"       )}>Episode Title</option>
                                <option value={getValue(props.readToshort,"Episode Release Date","Episode Release Date")}>Episode Release Date</option>
                                <option value={getValue(props.readToshort,"Media Release Date"  ,"Media Release Date"  )}>Media Release Date</option>
                                <option value={getValue(props.readToshort,"Media Air End Date"  ,"Media Air End Date"  )}>Media Air End Date</option>
                                <option value={getValue(props.readToshort,"Contract"  ,"Contract"  )}>Contract</option>
                                <option value={getValue(props.readToshort,"Rights"  ,"Rights"  )}>Rights</option>
                            </select>
                        </div>
                    </div>
                    <div className='column is-narrow'>
                        <button className={classInputFiles[1]+ " mr-1 is-primary"}  onClick={(e) => uploadExcel(e)} disabled={!isOnceDownload || filterTitle === ""}><i className="fa-solid fa-upload "></i></button>
                        <button className={classInputFiles[0]+ " is-info"}  onClick={(e) => {setIsOnceDownload(true);downloadExcel(e,props.jwt)}} disabled={filterTitle === ""}><i className="fa-solid fa-download"></i></button>
                    </div>
                </div>
    
                <div >
                    <Select options={[...selectDicti]}         
                    menuPortalTarget={document.body} 
                    onChange={(e) => changedSelect(e)}
                    value={catalogResFilt[theCatalog] !== undefined && catalogResFilt[theCatalog][props.indexFather] !== undefined && filterTitle in catalogResFilt[theCatalog][props.indexFather] ? catalogResFilt[theCatalog][props.indexFather][filterTitle].map((val) => {return {value : val,label:val}}) : null}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 60 }) }}
                    isMulti components={animatedComponents}  closeMenuOnSelect={false}/>
                </div>
                <div>
                    <span className="tag mt-3">
                        {"Counter: " + (catalogResFilt[theCatalog] !== undefined && catalogResFilt[theCatalog][props.indexFather] !== undefined && filterTitle in catalogResFilt[theCatalog][props.indexFather] ? catalogResFilt[theCatalog][props.indexFather][filterTitle].length : 0)}
                    </span>
                </div>
            </div>);
}
const SelectLineComponent = (props) =>
{
    const { catalogResFilt } = useSelector((state) => state.portal);
    const { theCatalog } = useSelector((state) => state.portal);
    const [selectComponents,setSelectValuesComponents] = useState([]);
    const [selectCount,setSelectCount] = useState(0);

    const dispatch = useDispatch();
    const delSelectedValue = () =>
    {
        let ary = [...selectComponents];
        ary = ary.slice(0,-1);
        if(ary.length === 0)
            dispatch(setActionToDo("delComp"+(props.index)));
        else
        {
            setSelectValuesComponents(ary);
            ary = [...catalogResFilt[theCatalog]];
            delete ary[props.index][Object.keys(ary[props.index])[-1]];
            dispatch(setCatalogResFilt({...catalogResFilt,[theCatalog] : ary}));
        }
    } 
    useEffect(() => {addSelectedValue(props.jwt)},[])  
    const addSelectedValue = (jwt) =>
    {
        setSelectValuesComponents([...selectComponents,<React.Fragment key={selectCount}><SelectValues indexFather={props.index} readToshort={props.readToshort} jwt={jwt} filterValues={props.filterValues}/></React.Fragment>]);
        setSelectCount(selectCount+1);
    }
    return  (<div className="box mb-2" style={props.catalog !== theCatalog ? {"display" : "none"} : {}}>
               <div className='columns'>
                    <div className='column' style={{"overflowX" : "scroll"}}><div className='columns'>{selectComponents}</div></div>
                    <div className='column is-narrow '>
                        <center><button className='delete is-pulled-right' onClick={() => dispatch(setActionToDo("delComp"+(props.index)))}></button><br/></center>
                        <button className='button is-rounded is-success is-light' style={{"height" : "35%"}} onClick={() => {addSelectedValue(props.jwt)}}>+</button><br/>
                        <button className='button is-rounded is-danger is-light' style={{"height" : "35%"}} onClick={() => delSelectedValue()}>-</button>
                    </div>
               </div>
            </div>);
}
export default SelectLineComponent;